// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880FF;
  --ion-color-primary-rgb: 223, 7, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1A67F0;
  --ion-color-primary-tint: #1A67F0;

  /** secondary **/
  --ion-color-secondary: #F07E26;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #00D39B;
  --ion-color-success-rgb: 0, 211, 155;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #00ba88;
  --ion-color-success-tint: #1ad7a5;

  /** warning **/
  --ion-color-warning: #F2CB2C;
  --ion-color-warning-rgb: 242, 203, 44;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d5b327;
  --ion-color-warning-tint: #f3d041;
  --ion-color-warning-light: #F8E59573;
  --ion-color-warning-dark: #AE7E17;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-danger-light: #F0391A26;
  --ion-color-danger-dark: #E03012;

  /** dark **/
  --ion-color-dark: #262626;
  --ion-color-dark-rgb: 38, 38, 38;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #212121;
  --ion-color-dark-tint: #3c3c3c;

  /** medium **/
  --ion-color-medium: #666666;
  --ion-color-medium-rgb: 102, 102, 102;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5a5a5a;
  --ion-color-medium-tint: #757575;

  /** light **/
  --ion-color-light: #EBEBEB;
  --ion-color-light-rgb: 235, 235, 235;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cfcfcf;
  --ion-color-light-tint: #ededed;

  /** barcode **/
  --ion-color-barcode: #E6E6E6;
  --ion-color-barcode-rgb: 146, 148, 156;
  --ion-color-barcode-contrast: #ffffff;
  --ion-color-barcode-contrast-rgb: 255, 255, 255;
  --ion-color-barcode-shade: #808289;
  --ion-color-barcode-tint: #9d9fa6;
  /** others properties **/
  --ion-font-family: "Roboto", sans-serif;
  --ion-border-color: #DADADA;
  --ion-color-background: #F8F8F8;
  --ion-color-light-gray: #979797;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #3880FF;
    --ion-color-primary-rgb: 223, 7, 0;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #1A67F0;
    --ion-color-primary-tint: #1A67F0;
    /** secondary **/
    --ion-color-secondary: #F07E26;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;
    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;
    /** success **/
    --ion-color-success: #00D39B;
    --ion-color-success-rgb: 0, 211, 155;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #00ba88;
    --ion-color-success-tint: #1ad7a5;
    /** warning **/
    --ion-color-warning: #F2CB2C;
    --ion-color-warning-rgb: 242, 203, 44;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d5b327;
    --ion-color-warning-tint: #f3d041;
    --ion-color-warning-light: #F8E59573;
    --ion-color-warning-dark: #AE7E17;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    --ion-color-danger-light: #F0391A26;
    --ion-color-danger-dark: #E03012;

    /** dark **/
    --ion-color-dark: #262626;
    --ion-color-dark-rgb: 38, 38, 38;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #212121;
    --ion-color-dark-tint: #3c3c3c;
    /** medium **/
    --ion-color-medium: #666666;
    --ion-color-medium-rgb: 102, 102, 102;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #5a5a5a;
    --ion-color-medium-tint: #757575;
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** barcode **/
    --ion-color-barcode: #E6E6E6;
    --ion-color-barcode-rgb: 146, 148, 156;
    --ion-color-barcode-contrast: #ffffff;
    --ion-color-barcode-contrast-rgb: 255, 255, 255;
    --ion-color-barcode-shade: #808289;
    --ion-color-barcode-tint: #9d9fa6;

    /** others properties **/
    --ion-font-family: "Roboto", sans-serif;
    --ion-border-color: #DADADA;
    --ion-color-background: #F8F8F8;
    --ion-color-light-gray: #979797;
  }

  @media (prefers-color-scheme: dark) {

    /*
   * Dark Colors
   * -------------------------------------------
   */
    body {
      /*  --ion-color-primary: #DF0700;
        --ion-color-primary-rgb: 66, 140, 255;
        --ion-color-primary-contrast: #ffffff;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-color-primary-shade: #3a7be0;
        --ion-color-primary-tint: #5598ff;
        --ion-color-secondary: #50c8ff;
        --ion-color-secondary-rgb: 80, 200, 255;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #46b0e0;
        --ion-color-secondary-tint: #62ceff;
        --ion-color-tertiary: #6a64ff;
        --ion-color-tertiary-rgb: 106, 100, 255;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #5d58e0;
        --ion-color-tertiary-tint: #7974ff;
        --ion-color-success: #2fdf75;
        --ion-color-success-rgb: 47, 223, 117;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0, 0, 0;
        --ion-color-success-shade: #29c467;
        --ion-color-success-tint: #44e283;
        --ion-color-warning: #ffd534;
        --ion-color-warning-rgb: 255, 213, 52;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0bb2e;
        --ion-color-warning-tint: #ffd948;
        --ion-color-danger: #ff4961;
        --ion-color-danger-rgb: 255, 73, 97;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #e04055;
        --ion-color-danger-tint: #ff5b71;
        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244, 245, 248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;
        --ion-color-medium: #989aa2;
        --ion-color-medium-rgb: 152, 154, 162;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0, 0, 0;
        --ion-color-medium-shade: #86888f;
        --ion-color-medium-tint: #a2a4ab;
        --ion-color-light: #222428;
        --ion-color-light-rgb: 34, 36, 40;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #1e2023;
        --ion-color-light-tint: #383a3e; */
    }

    /*
   * iOS Dark Theme
   * -------------------------------------------
   */
    .ios body {
      /*  --ion-background-color: #000000;
        --ion-background-color-rgb: 0, 0, 0;
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;
        --ion-toolbar-background: #0d0d0d;
        --ion-item-background: #000000;
        --ion-card-background: #1c1c1d; */
    }

    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
    .md body {
      /* --ion-background-color: #121212;
        --ion-background-color-rgb: 18, 18, 18;
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-border-color: #222222;
        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;
        --ion-item-background: #1e1e1e;
        --ion-toolbar-background: #1f1f1f;
        --ion-tab-bar-background: #1f1f1f;
        --ion-card-background: #1e1e1e; */
    }
  }
}
